LemonFrog.Turbo = (function() {
  const TURBO_REDIRECT_STATUS = 300;

  function init() {
    // close dialog on successful form submission
    $(document).on("turbo:submit-end", closeDialog)
    // reload all turbo frames in a dialog when it's hiding
    $(document).on('dialog.hidden', '[data-turbo-dialog-frame-reload="true"]', reloadFrames);
    // The following code is needed to process redirect requests from turbo-powered forms.
    // The app is not ready for JS body replacement.
    // 21.12.2021
    // This will prevent Turbo Drive form working properly.
    // Needs to be removed when app is ready to use it.
    $(document).on('turbo:before-fetch-response', redirect);
    // Attach jquery-ujs disabling elements behavior to turbo:submit-start event
    $(document).on('turbo:submit-start', disableFormElements);
    // Attach jquery-ujs enabling elements behavior to turbo:submit-end event
    $(document).on('turbo:submit-end', enableFormElements);
    // init parsley.js validation for turbo frames with forms
    $(document).on("turbo:frame-load", initFormsValidation)
  }

  function closeDialog($event) {
    let result = $event.originalEvent.detail;
    let form = result.formSubmission.formElement;

    if (result.success && form.dataset["turboDialogDismissOnSuccess"]) {
      LemonFrog.Dialog.dismissDialog($(form).parents('.dialog'));
    }
  }

  function reloadFrames() {
    $(this).find('turbo-frame').each(function () {
      this.reload();
    });
  }

  function redirect($event) {
    let res = $event.originalEvent.detail.fetchResponse.response;

    if (res.status === TURBO_REDIRECT_STATUS) {
      $event.preventDefault();
      window.location = res.headers.get("Location");
    }
  }

  function disableFormElements($event) {
    let $form = $($event.detail.formSubmission.formElement);
    let $submitter = $($event.detail.formSubmission.submitter);

    // $submitter is disabled internally by turbo before firing 'turbo:submit-start' event.
    // Enabling it here to make $.rails.disableFormElements disable the submitter element as well.
    // It uses $.rails.disableSelector inside, which only selects :enabled elements.
    $submitter.prop('disabled', false);
    $.rails.disableFormElements($form);
  }

  function enableFormElements($event) {
    let $form = $($event.detail.formSubmission.formElement);
    let $submitter = $($event.detail.formSubmission.submitter);

    // $submitter is enabled internally by turbo before firing 'turbo:submit-start' event.
    // Enabling it here to make $.rails.enableFormElements enable the submitter element as well.
    // It uses $.rails.enableSelector inside, which only selects :disabled elements.
    $submitter.prop('disabled', true);
    $.rails.enableFormElements($form);
  }

  function initFormsValidation($event) {
    $($event.target).find('form.js-form-parsley').parsley();
  }

  return {
    init: init
  }
}());

LemonFrog.Turbo.init();
