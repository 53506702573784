LemonFrog.Tooltip = (function() {
  const moduleName = 'Tooltip';

  function init() {
    place();
    assignListeners();
  }

  function place(tooltip = $('.tooltip')) {
    tooltip.each(function () {
      const $this = $(this);
      dropPlaceAdjustments($this);
      let box = getBoundingBox($this);

      rotateToVisible($this, box);
      box = getBoundingBox($this);

      placeHorizontally($this, box);
      placeVertically($this, box);
    })
  }

  function assignListeners() {
    $(document).ready(function() {
      $(".tooltip-wrapper").mouseenter(function() {
        LemonFrog.Tooltip.place($(this).find(".tooltip"));
      })
    })
  }

  // shift tooltip horizontally if it's box is outside of the viewport
  function placeHorizontally($tooltip, box) {
    const side = getSide($tooltip);
    if (side === 'right' || side === 'left') return;

    if (box.right > document.body.clientWidth) {
      $tooltip.css('margin-left', -((box.right - document.body.clientWidth) * 2 + 32));
    } else if (box.left < 0) {
      $tooltip.css('margin-right', box.left * 2 - 32)
    }
  }

  // shift tooltip vertically if it's box is outside of the viewport
  function placeVertically($tooltip, box) {
    const side = getSide($tooltip);
    if (side === 'top' || side === 'bottom') return;

    if (box.bottom > window.innerHeight) {
      $tooltip.css('margin-top', -((box.bottom - document.body.clientWidth) * 2 + 32));
    } else if (box.top < 0) {
      $tooltip.css('margin-bottom', box.top * 2 - 32)
    }
  }

  // selects and applies the most visible side for tooltip
  function rotateToVisible($tooltip, box) {
    let side = getSide($tooltip);

    const $wrapper = $tooltip.closest('.tooltip-wrapper');
    // used later to restore initial side if resized window will fit the tooltip
    // on that side
    $tooltip.data('tooltipInitialSide', side)

    // if there's not enough space to place the tooltip on selected side
    // switch side to top by removing respected classes
    if (side === 'right' && box.right > document.body.clientWidth) {
      $wrapper.removeClass('right');
    } else if (side === 'left' && box.left < 0) {
      $wrapper.removeClass('left');
    } else if (side === 'bottom' && box.bottom > window.innerHeight) {
      $wrapper.removeClass('bottom');
    }

    if (getBoundingBox($tooltip).top < 0 && getSide($tooltip) === 'top') {
      $wrapper.addClass('bottom');
    }
  }

  function dropPlaceAdjustments($tooltip) {
    $tooltip.css('margin-left', '');
    $tooltip.css('margin-right', '');
    $tooltip.css('margin-top', '');
    $tooltip.css('margin-bottom', '');

    if ($tooltip.data('tooltipInitialSide')) {
      const $wrapper = $tooltip.closest('.tooltip-wrapper');
      $wrapper.removeClass(['right', 'left', 'bottom', 'top']);
      $wrapper.addClass($tooltip.data('tooltipInitialSide'));
    }
  }

  function getSide($tooltip) {
    const $wrapper = $tooltip.closest('.tooltip-wrapper');
    return ['right', 'left', 'bottom'].find(el => $wrapper.hasClass(el)) || 'top';
  }

  function getBoundingBox($tooltip) {
    $tooltip.show();
    const box = $tooltip.get(0).getBoundingClientRect();
    $tooltip.css('display', '');
    return box;
  }

  return {
    init: init,
    place: place,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.Tooltip)
