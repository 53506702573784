class Antiflicker {
  constructor() {
    this.callbacks = []
  }

  add(callback) {
    this.callbacks = [...this.callbacks, callback]
  }

  execute() {
    return Promise.allSettled(
      this.callbacks.map((c) => new Promise(c))
    );
  }
}

let antiflick = new Antiflicker()
let antiflicker = (callback) => { antiflick.add(callback) }
window.antiflick = antiflick;
window.antiflicker = antiflicker;

export { antiflick, antiflicker };

$(() => {
  antiflick.execute().then(() => {
    $('.js-antiflicker').animate({ opacity: 1 }, 300).removeClass('js-antiflicker');
  });
});

