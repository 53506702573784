LemonFrog.AddToFavouriteComponent = (function () {
  const moduleName = "FavouritesActions"

  function init() {
    $(document).on("turbo:submit-end", replaceNavigationLink)
  }

  function replaceNavigationLink(e) {
    let result = e.originalEvent.detail;
    let form = result.formSubmission.formElement;
    let backLink = form.elements["favourite_list[back_link]"].value

    if (result.success && backLink.length) {
      let navigationLink = $("#navigation_back_link")

      navigationLink.each((_, link) => {
        $(link).attr("href", backLink)
      })
    }
  }

  return {
    init: init,
    moduleName: moduleName
  };
}());

LemonFrog.initModule(LemonFrog.AddToFavouriteComponent);
