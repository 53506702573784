LemonFrog.SearchInCitiesComponent = (function () {
  let moduleName = "SearchInCitiesComponent",
      moduleSel  = `[data-module="${moduleName}"]`;

  function init() {
    // Script is createad to calcute the amount of columns visible in grid
    // Taking in account that text inside can be wide and become truncated because of that
    // Our goal is to keep 5 columns in the wide parent and show
    // flexible amount of columns avoiding truncating in the narrow parent

    const gridWidth = $(moduleSel).width()
    const colMinWidth = $(moduleSel).find('a').innerWidth()

    if((gridWidth / 5) < colMinWidth) {
      return compactizeGrid(colMinWidth)
    }

    $(moduleSel).addClass('home-search-in-cities--large')
  }

  function compactizeGrid(value) {
    document.body.style.setProperty(
      '--cities-width', `${value}px`
    );
    $(moduleSel).addClass('home-search-in-cities--compact')
  }

  return {
    moduleName: moduleName,
    init: init,
  }
}());

LemonFrog.initModule(LemonFrog.SearchInCitiesComponent);
