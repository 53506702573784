// Similar to Stimilus OutletObserver calling controller#connect

const processAttributeChange = function(mutation) {
  if ($(mutation.target).filter(":hidden").length > 0) return;
  const targets = mutation.target.querySelectorAll("[data-onvisible]");

  $(targets).each(function(idx, target) {
    eval(target.dataset.onvisible);
  });
};

const processAddedNodes = function(mutation) {
  mutation.addedNodes.forEach(function(node) {
    if (node.nodeType != Node.ELEMENT_NODE) return;
    const targets = node.querySelectorAll("[data-onready]");

    $(targets).each(function(idx, target) {
      eval(target.dataset.onready);
    });
  });
};

const elementObserver = new MutationObserver(function(entries) {
  entries.forEach(function(entry) {
    if (entry.type == "attributes") {
      processAttributeChange(entry);
    } else if (entry.addedNodes.length > 0) {
      processAddedNodes(entry);
    }
  });
});

$(document).ready(function () {
  elementObserver.observe(
    document.body,
    { childList: true, subtree: true,
      attributes: true, attributeFilter: ["style", "class"] }
  )
});
