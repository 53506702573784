LemonFrog.Dialog = (function() {
  function init() {
    initDialogTrigger();
    initAnimatePersistentDialog();
    initDialogDismiss();
    initBackButtonDismiss();
  }

  function initDialogTrigger() {
    $(document).on('click', '[data-open-dialog]', function (event) {
      event.preventDefault();
      event.stopPropagation();

      const dialog = $(event.currentTarget).data('open-dialog');

      if ($(dialog).length) showDialog(dialog);
    });
  }

  function initAnimatePersistentDialog() {
    $(document).on('click', '.js-animate-persistent-dialog', function(event) {
      const parentDialog = $(event.currentTarget).parents('.dialog');

      animatePersistentDialog(parentDialog);
    });

    $(document).on('keydown', '.new-styling.dialog.js-persistent-dialog', function (event) {
      if (event.keyCode !== 27) return;

      animatePersistentDialog(this);
    });
  }

  function initDialogDismiss() {
    $(document).on('click', '.js-dismiss-dialog', function (event) {
      const parentDialog = $(event.currentTarget).parents('.dialog');

      dismissDialog(parentDialog);
    });

    $(document).on('keydown', '.new-styling.dialog:not(.js-persistent-dialog)', function (event) {
      if (event.keyCode !== 27) return;

      dismissDialog(this);
    });
  }

  function initBackButtonDismiss() {
    window.addEventListener('hashchange', function() {
      if (window.location.href.endsWith('#dialog')) return
      const parentDialog = $('.new-styling.dialog').filter(":visible");

      dismissDialog(parentDialog);
    }, false);
  }

  function showDialog(dialogSelector, callback) {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;

    $('body').css({ overflow: 'hidden', paddingRight: scrollbarWidth + 'px' });

    $(dialogSelector).trigger($.Event('dialog.show'))
                     .css('opacity', '0')
                     .removeClass('hidden')
                     .stop(true, true).animate({
      opacity: 1,
    }, 300, function() {
      $(dialogSelector).css('opacity', '')
                       .attr({ 'aria-hidden': null, 'aria-modal': true })
                       .focus()
                       .trigger($.Event('dialog.shown'));

      if (callback) callback();
    });

    window.location = '#dialog';
  }

  function dismissDialog(dialogSelector, callback) {
    $(dialogSelector).trigger($.Event('dialog.hide'))
                     .stop(true, true).animate({
      opacity: 0,
    }, 300, function() {
      $('body').css({ overflow: '', paddingRight: '' });

      $(dialogSelector).css('opacity', '')
                       .addClass('hidden')
                       .attr({ 'aria-hidden': true, 'aria-modal': null })
                       .trigger($.Event('dialog.hidden'));

      if (callback) callback();
    });
  }

  function toggleDialog(dialogSelector) {
    if ($(dialogSelector).hasClass('hidden')) {
      showDialog(dialogSelector);
    } else {
      dismissDialog(dialogSelector);
    }
  }

  function animatePersistentDialog(dialogSelector) {
    $(dialogSelector).find('.new-styling.dialog-content').addClass('dialog-content--animated');
    setTimeout(function () {
      $(dialogSelector).find('.new-styling.dialog-content').removeClass('dialog-content--animated');
    }, 150);
  }

  return {
    init: init,
    showDialog: showDialog,
    dismissDialog: dismissDialog,
    toggleDialog: toggleDialog,
    animatePersistentDialog: animatePersistentDialog
  }
}());

LemonFrog.Dialog.init();
