LemonFrog.Scheduler = (function () {
  const moduleName = 'Scheduler',
    moduleSel = '[data-module="' + moduleName + '"]',
    frequencyRegularlyFieldMeaning = 'scheduler_frequency_regularly',
    frequencyOneTimeFieldMeaning = 'scheduler_frequency_one_time',
    frequencyOccasionalFieldMeaning = 'scheduler_frequency_occasional',
    startFromFieldMeaning = 'scheduler_start_from',
    frequencySelectSection = '[data-meaning="scheduler_frequency"]',
    daysSelector = '[data-meaning="scheduler_days"]',
    startListSection = '[data-meaning="scheduler_start_list"]',
    hoursFieldSelect = '.js-scheduler-hours',
    daysFieldSelect = '.js-scheduler-days',
    regularlyConditionalElementsSelectors = [
      '.js-scheduler-table-wrapper',
      '[data-meaning="scheduler_start_date"]',
      '[data-meaning="scheduler_table"]',
      '[data-meaning="scheduler_hours"]',
      '[data-meaning="scheduler_days"]',
    ],
    notRegularyConditionalFieldsSelectors = [
      '[data-meaning="scheduler_start_date"]',
      '[data-meaning="scheduler_hours"]'
    ],
    hoursFlexibleOptionSelector = '[data-meaning="scheduler_hours_flexible"]',
    hoursLabelSelector = "[data-meaning='scheduler_hours'] label"
  ;

  let hoursFlexibleOption;
  function init() {
    if ($('body').hasClass('wizard')) {
      $(moduleSel).parent().children('.hint').remove();
    }

    initEvents();
    if ($(startListSection).length) onStartListChange();
  }

  function initEvents() {
    showRelatedFields(getSelectedFrequencyMeaning());
    $(moduleSel).find(frequencySelectSection).on("change", "select, input", onFrequencyChange);
    $(moduleSel).find(startListSection).find("input[type=radio]").on("change", onStartListChange);
  }

  function onStartListChange() {
    let $section = $(startListSection);
    let $input = $(startListSection).find("input[type=radio]:checked");

    if ($input.data('meaning') == startFromFieldMeaning) {
      $input.closest(".radio").find(".radio__append .js-scheduler-start-date")
        .prop("disabled", false);
      $section.find(".radio__append .new-styling.input-field").removeClass("disabled hidden");
    } else {
      $section.find(".radio__append .js-scheduler-start-date")
        .val("").prop("disabled", "disabled");
      $section.find(".radio__append .new-styling.input-field").addClass("disabled hidden");
      $section.find(".radio__append .js-scheduler-start-date").parsley().reset();
    }
  }

  function onFrequencyChange() {
    showRelatedFields(getSelectedFrequencyMeaning());
    $(moduleSel).find(hoursFieldSelect + " option:selected").prop("selected", false);
    $(moduleSel).find(daysFieldSelect + " option:selected").prop("selected", false);
  }

  function showRelatedFields(meaning) {
    hideAllConditionalFields();

    switch(meaning) {
      case frequencyRegularlyFieldMeaning:
        setHoursSelector({ showFlexibleOption: false, restrictHours: false, defaultHoursLabel: true});
        $(regularlyConditionalElementsSelectors.join(',')).show();
        break;
      case frequencyOneTimeFieldMeaning:
        setHoursSelector({ showFlexibleOption: true, restrictHours: false, defaultHoursLabel: true});
        $(notRegularyConditionalFieldsSelectors.join(',')).show();
        break;
      case frequencyOccasionalFieldMeaning:
        setHoursSelector({ showFlexibleOption: false, restrictHours: true, defaultHoursLabel: false});
        $(notRegularyConditionalFieldsSelectors.join(',')).show();
        break;
    }
  }

  function setHoursSelector(params) {
    showFlexibleOption(params["showFlexibleOption"]);
    restrictHours(params["restrictHours"]);
    changeHoursLabel(params["defaultHoursLabel"]);
  }

  function addHoursFlexibleOption() {
    if ($(hoursFlexibleOptionSelector).length) return;

    $(hoursFieldSelect + " option:first-child").after(hoursFlexibleOption);
  }

  function removeHoursFlexibleOption() {
    if (!$(hoursFlexibleOptionSelector).length) return;

    hoursFlexibleOption = $(hoursFlexibleOptionSelector).remove();
  }

  function restrictHours(on) {
    const maxRestirctedHour = 8;

    $(hoursFieldSelect + " option").each(function () {
      let hour = parseInt($(this).text());
      if (hour > 0 && hour >  maxRestirctedHour) {
        on ? $(this).hide() : $(this).show();
      }
    });
  }

  function changeHoursLabel(isDefault) {
    let text = isDefault ? $(moduleSel).data("hours-label") : $(moduleSel).data("occasional-hours-label");
    $(hoursLabelSelector).each((i, el) => {
      if ($(el).closest(daysSelector).length > 0) return;

      $(el).text(text);
    })
  }

  function hideAllConditionalFields() {
    let allConditionalFieldsSelectors = regularlyConditionalElementsSelectors.concat(
                                          notRegularyConditionalFieldsSelectors,
                                        );

    $(allConditionalFieldsSelectors.join(',')).hide();
  }

  function showFlexibleOption(show) {
    show ? addHoursFlexibleOption() : removeHoursFlexibleOption();
  }

  function getSelectedFrequencyMeaning() {
    if ($(moduleSel).find(frequencySelectSection).find('select').length) {
      return $(moduleSel).find(frequencySelectSection).find('option:selected').data('meaning');
    } else {
      return $(moduleSel).find(frequencySelectSection).find('input:checked').data('meaning');
    }
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.Scheduler);
